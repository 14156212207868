
import React, {useContext, useEffect, useState} from 'react';

import MapContext ,{MapContextProvider} from "./MapContext";
import {
    AppBar,
    Box, Button, Container,
    Drawer,
    Grid,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Paper,
    Toolbar,
    Typography
} from "@mui/material";

import axios from "axios";

import {getDistance} from "geolib";
import VideoList from "./VideoList";
import IconButton from "@mui/material/IconButton";
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


function InboxIcon() {
    return null;
}

const MainPage = () => {


   const {populateMap} = useContext(MapContext);

    const [Videos, setVideos] = useState([]);
    const [expanded, setExpanded] = useState(true);

    useEffect(() => {
        const fetchVideos = async () => {
            const response = await axios.get('https://api.videomaps.io/getChannelDetailsByUrl/@markwiens');
            const jsonresponse = JSON.parse(JSON.stringify(response.data));

            const videoLocations = JSON.parse(JSON.stringify(jsonresponse.videoLocationDetailsList));
            const uniqueVideoLocations = new Map();

            videoLocations.forEach(location => {
                if (!uniqueVideoLocations.has(location.videoId)) {
                    uniqueVideoLocations.set(location.videoId, location);
                }
            });
            const deduplicatedVideoLocations = Array.from(uniqueVideoLocations.values());
            const videoList = deduplicatedVideoLocations;
            return { videoList, videoLocations };
        };

        const loadMapKit = () => {
            return new Promise((resolve) => {
                let mapkit;
                const script = document.createElement("script");
                script.src = "https://cdn.apple-mapkit.com/mk/5.x.x/mapkit.core.js";
                script.async = true;
                script.crossOrigin = "";
                script.dataset.callback = "initMapKit";
                script.dataset.libraries = "full-map,annotations";
                document.head.appendChild(script);

                window.initMapKit = function() {
                    let mapkit = window.mapkit;
                    mapkit.init({
                        authorizationCallback: function(done) {
                            fetch('https://api.videomaps.io/getAppleMapsToken', {
                                method: 'GET',
                            }).then(res => res.text())
                                .then(token => done(token))
                                .catch(error => console.log('token error'));
                        }
                    });

                    const map = new mapkit.Map("map");
                    map.showsUserLocationControl = true;
                    map.showsZoomControl = true;
                    map.showsCompass = true;
                    resolve({ map, mapkit });
                };
            });
        };

        const initialize = async () => {
            const [{ videoList, videoLocations }, { map, mapkit }] = await Promise.all([fetchVideos(), loadMapKit()]);

            setVideos(videoList);
            //setVideos(videoLocations);


            const annotationsMap = new Map();
            for (let i = 0; i < videoLocations.length; i++) {
                const { longitude, latitude, videoId, clusterIdentifier } = videoLocations[i];
                const center = new mapkit.Coordinate(latitude, longitude);
                const annotation = new mapkit.MarkerAnnotation(center, {});
                annotation.clusteringIdentifier = clusterIdentifier;

                if (!annotationsMap.has(clusterIdentifier)) {
                    annotationsMap.set(clusterIdentifier, []);
                }
                annotationsMap.get(clusterIdentifier).push(annotation);
            }

            const annotationsArray = [];
            annotationsMap.forEach((annotations, clusterIdentifier) => {
                annotationsArray.push(annotations);
            });

            annotationsArray.sort((a, b) => b.length - a.length);

            let delay = 0;
            annotationsArray.forEach(annotations => {
                setTimeout(() => {
                    annotations.forEach(annotation => {
                        map.addAnnotation(annotation);
                    });
                }, delay);
                delay += 100;
            });
        };

        initialize();
    }, []);


    const handleToggleExpand = () => {
        setExpanded(!expanded);
    };

    return (

        <div style={{width: '100%', height: window.innerHeight, overflow: 'hidden'}}>

            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                overflow: 'hidden'
            }}>
                <AppBar position="static">
                    <Toolbar>
                        <Typography variant="h6">Header</Typography>
                    </Toolbar>
                </AppBar>


                <Box sx={{flex: 1, display: 'flex', overflow: 'hidden', paddingBottom: 0.5}}>
                    <Box sx={{
                        width: '100%',
                        height: 'calc(100% - 16px)',
                        paddingTop: 0.5,
                        paddingBottom: 0.5,
                        position: 'relative',
                        overflow: 'hidden'
                    }}>
                        <div id="map" style={{width: '100%', height: '100%', overflow: 'hidden'}}/>
                        <Box sx={{position: 'absolute', bottom: 2, width: '100%'}}>

                            <Button
                                onClick={handleToggleExpand}
                                sx={{
                                    position: 'absolute',
                                    top: '-48px',
                                    left: '50%',
                                    transform: 'translateX(-50%)',
                                    backgroundColor: 'rgba(255, 255, 255, 0.8)',
                                    padding: '8px 16px',
                                    borderRadius: '4px',
                                    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)'
                                }}
                            >
                                {expanded ? 'Hide Videos' : 'Show Videos'}
                            </Button>

                            {expanded && (
                                <Box sx={{paddingBottom: '16px'}}>
                                    <VideoList VideoList={Videos}/>
                                </Box>
                            )}
                        </Box>
                    </Box>

                </Box>

            </Box>
        </div>
    );
}

export default MainPage;

