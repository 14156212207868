import React, { useContext, useEffect, useState } from "react";
import MapContext from "./MapContext";
import VideoDetails from "./VideoDetails";
import {
    Box,
    Typography
} from "@mui/material";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import IconButton from '@mui/material/IconButton';

function VideoList({ VideoList }) {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [selectedVideo, setSelectedVideo] = useState(null);

    const { MapKit, Map, populateMap } = useContext(MapContext);
    const annotations = Map.annotations;

    useEffect(() => {
        const updateRowsPerPage = () => {
            const width = window.innerWidth;
            if (width < 600) {
                setRowsPerPage(1);
            } else if (width < 960) {
                setRowsPerPage(3);
            } else {
                setRowsPerPage(5);
            }
        };

        updateRowsPerPage();
        window.addEventListener('resize', updateRowsPerPage);

        return () => {
            window.removeEventListener('resize', updateRowsPerPage);
        };
    }, []);

    const handlePrevPage = () => {
        setPage((prevPage) => Math.max(prevPage - 1, 0));
    };

    const handleNextPage = () => {
        setPage((prevPage) => Math.min(prevPage + 1, Math.ceil(VideoList.length / rowsPerPage) - 1));
    };

    function highlightAnnotation(videoId) {
        annotations.forEach(annotation => {
            if (annotation.videoId === videoId) {
                annotation.selected = true;
                const center = new MapKit.Coordinate(annotation.coordinate.latitude, annotation.coordinate.longitude);
                const span = new MapKit.CoordinateSpan(0.01, 0.01);
                const region = new MapKit.CoordinateRegion(center, span);
                Map.region = region;
                Map.setCenterAnimated(center, true);
            } else {
                annotation.selected = false;
            }
        });
    }

    const handleVideoSelect = (videoId) => {
        setSelectedVideo(videoId);
        highlightAnnotation(videoId);
    };

    return (
        <Box sx={{ display: 'flex', alignItems: 'center',overflow: 'hidden' }}>
            <IconButton onClick={handlePrevPage} disabled={page === 0}>
                <ArrowBackIosIcon />
            </IconButton>
            <Box sx={{ display: 'flex', overflowX: 'auto', flexGrow: 1 ,overflow: 'hidden' }}>
                {VideoList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((video) => (
                    <Box key={video.videoId} flexGrow={1} sx={{ marginRight: '2rem' }} >
                        <VideoDetails Video={video} VideoId={'https://www.youtube.com/embed/' + video.videoId} Vid={video.videoId} />
                    </Box>
                ))}
            </Box>
            <IconButton onClick={handleNextPage} disabled={page >= Math.ceil(VideoList.length / rowsPerPage) - 1}>
                <ArrowForwardIosIcon />
            </IconButton>
        </Box>
    );
}

export default VideoList;