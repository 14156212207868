import {createContext, useEffect, useState} from "react";


const MapContext = createContext();

function MapContextProvider({children}) {
   const [map, setMap] = useState({});
    const [mapkit, setMapkit] = useState({});
    //var mapkit ;

   // console.log("mapkit "+mapkit);

    useEffect(() => {
        // // Create a new script element
        // const script = document.createElement("script");
        //
        // // Set the src attribute to the MapKit JS URL
        // script.src = "https://cdn.apple-mapkit.com/mk/5.x.x/mapkit.core.js";
        //
        // // Set the async attribute
        // script.async = true;
        //
        // // Set the crossorigin attribute
        // script.crossOrigin = "";
        //
        // // Set the data-callback attribute
        // script.dataset.callback = "initMapKit";
        //
        // // Set the data-libraries attribute
        // script.dataset.libraries = "full-map,annotations";
        //
        // // Append the script element to the document's head
        // document.head.appendChild(script);
        // // Initialize MapKit JS
        // window.initMapKit = function() {
        //     //console log current time
        //     console.log("initMapKit");
        //     let now = new Date();
        //     let time = now.getHours() + ":" + now.getMinutes() + ":" + now.getSeconds() + ":" + now.getMilliseconds();
        //     console.log("context:" + time);
        //     mapkit = window.mapkit;
        //     // Initialize MapKit JS
        //   mapkit.init({
        //         authorizationCallback: function(done) {
        //             fetch('https://api.videomaps.io/getAppleMapsToken', {
        //                 method: 'GET',
        //             }).then(res => res.text())
        //                 .then(token => done(token)) /* If successful, return your token to MapKit JS */
        //                 .catch(error => console.log('token error'));
        //         }
        //     });
        // }
    },[]);

    const valueToPass = {

        MapKit: mapkit,
        Map: map,
        populateMap: (map,mapkit) => {
            console.log("MapContext Map: "+map);
            setMap(map);
            setMapkit(mapkit);
        }
    };

    return(
        <MapContext.Provider value={valueToPass}>
            {children}
        </MapContext.Provider>
    );
}
export {MapContextProvider};

export default MapContext;