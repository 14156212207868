

import MainPage from "./components/MainPage";
import React from "react";
import { Amplify } from 'aws-amplify';
//import { Authenticator } from '@aws-amplify/ui-react';
// import '@aws-amplify/ui-react/styles.css';


// Amplify.configure({
//     Auth: {
//         // Your authentication configuration goes here
//         // e.g., for Cognito User Pools:
//         userPoolId: 'us-west-2_xAFABLfSs',
//         userPoolWebClientId: '1d12ro9nlispiki4lh9qgamah5',
//         // or for OAuth:
//
//     }
// });

function App() {
  return (
    <div>
        <MainPage/>
        {/*<Authenticator>*/}
        {/*    {({ signOut, user }) => (*/}
        {/*        <main>*/}
        {/*            <h1>Hello {user.username}</h1>*/}
        {/*            <button onClick={signOut}>Sign out</button>*/}
        {/*        </main>*/}
        {/*    )}*/}
        {/*</Authenticator>*/}
      </div>


  );
}

export default App;
