
import '../App.css';

import React, {useContext, useEffect, useState} from "react";


import MapContext ,{MapContextProvider} from "./MapContext";
import {Accordion, AccordionDetails, AccordionSummary, FormControlLabel, Radio, Typography} from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

function VideoDetails({VideoId,Video,Vid}) {



    //var mapkit = window.mapkit;  //
    const {MapKit,Map,populateMap} = useContext(MapContext);
    const annotations = Map.annotations;
    useEffect(() => {

    },[Video]);

    const srcdoc = `
    <style>
      * {
        padding: 0;
        margin: 0;
        overflow: hidden;
      }
      body, html {
        height: 100%;
      }
      img, svg {
        position: absolute;
        width: 100%;
        top: 0;
        bottom: 0;
        margin: auto;
      }
      svg {
        filter: drop-shadow(1px 1px 10px hsl(206.5, 70.7%, 8%));
        transition: all 250ms ease-in-out;
      }
      body:hover svg {
        filter: drop-shadow(1px 1px 10px hsl(206.5, 0%, 10%));
        transform: scale(1.2);
      }
    </style>
    
    <a href='https://www.youtube.com/embed/${Vid}?autoplay=1'>
      <img src='https://img.youtube.com/vi/${Vid}/hqdefault.jpg' alt='' />
      <svg xmlns='http://www.w3.org/2000/svg' width='64' height='64' viewBox='0 0 24 24' fill='none' stroke='#ffffff' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' className='feather feather-play-circle'><circle cx='12' cy='12' r='10'></circle><polygon points='10 8 16 12 10 16 10 8'></polygon></svg>
    </a>
  `;

    const [selectedLocation, setSelectedLocation] = useState(null);

    function highlightAnnotation(location) {

        //find annotation with videoId and highlight it
        annotations.forEach(annotation => {
            if(annotation.coordinate.latitude === location.latitude && annotation.coordinate.longitude === location.longitude) {
                annotation.selected =  true;
                const center = new MapKit.Coordinate(annotation.coordinate.latitude,annotation.coordinate.longitude); // Hyderabad
                const span = new MapKit.CoordinateSpan(0.01, 0.01);
                const region = new MapKit.CoordinateRegion(center, span);
                Map.region = region;
                Map.setCenterAnimated(center, true);


            } else {
                annotation.selected =  false;
            }
        });
    }

    const handleLocationSelect = (event, location) => {
        setSelectedLocation(location);
        highlightAnnotation(location)
    };


    return (

        <div style={{
            width: '100%',
            maxWidth: '100%',
        }}>
                    <div style={{position: 'relative', paddingBottom: '56.15%', height: 0, overflow: 'hidden'}}>
                        <iframe
                            style={{position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', border: 0}}
                            loading="lazy"
                            srcDoc={srcdoc}
                            src={`https://www.youtube.com/embed/${Vid}`}
                            title="test"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                        ></iframe>
                    </div>


            <div>
                {/* Using ternary operator */}
            </div>
        </div>
    );
}

export default VideoDetails;


{/*<Accordion>*/}
{/*    <AccordionSummary*/}
{/*        expandIcon={<ExpandMoreIcon />}*/}
{/*        aria-controls="panel1a-content"*/}
{/*        id="panel1a-header"> Location Details*/}
{/*    </AccordionSummary>*/}
{/*    <AccordionDetails>*/}
{/*        {Video.videoLocationDetailsList.map((location) => (*/}
{/*            <FormControlLabel*/}
{/*                key={location.latitude+'_'+location.longitude}*/}
{/*                value={location.latitude+'_'+location.longitude}*/}
{/*                control={<Radio*/}
{/*                    checked={selectedLocation === location.latitude+'_'+location.longitude}*/}
{/*                    onChange={(event) => handleLocationSelect(event, location)}*/}
{/*                />}*/}
{/*                label={location.locationName}*/}
{/*            />*/}
{/*        ))}*/}
{/*    </AccordionDetails>*/}
{/*</Accordion>*/}

// <div style={{
//     width: '100%',
//     maxWidth: '550px',
//     boxShadow: '6px 6px 10px hsl(206.5, 0%, 75%)',
//     margin: '2rem'
// }}>
//     <div style={{position: 'relative', paddingBottom: '56.15%', height: 0, overflow: 'hidden'}}>
//         <iframe
//             style={{position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', border: 0}}
//             loading="lazy"
//             //srcDoc={srcdoc}
//             src={`https://www.youtube.com/embed/${Vid}`}
//             title="test"
//             frameBorder="0"
//             allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
//             allowFullScreen
//         ></iframe>
//     </div>
//     <div>
//
//
//         {/* Using ternary operator */}
//
//
//     </div>
//
// </div>
//
//
//
// );


// <div style={{
//     width: '100%',
//     maxWidth: '550px',
//     boxShadow: '6px 6px 10px hsl(206.5, 0%, 75%)',
//     margin: '2rem'
// }}>
//     <div style={{position: 'relative', paddingBottom: '56.15%', height: 0, overflow: 'hidden'}}>
//         <iframe
//             style={{position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', border: 0}}
//             loading="lazy"
//             srcDoc={srcdoc}
//             src={`https://www.youtube.com/embed/${Vid}`}
//             title="test"
//             frameBorder="0"
//             allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
//             allowFullScreen
//         ></iframe>
//     </div>
//     <div>
//
//
//         {/* Using ternary operator */}
//
//
//     </div>
//
// </div>